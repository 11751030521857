.home_wrap {
  width: 68%;
  padding: 30px 0;
  min-height: 100vh;
  overflow-y: auto;
}

.c_container {
  width: 95%;
  margin: 0 auto;
}

.home_wrap .c_container {
  display: flex;
  flex-direction: column;
}

.h_head {
  width: 100%;
}

.h_head h2 {
  font-size: 1.5rem;
  color: #160e4d;
  font-weight: 400;
}

.h_head div {
  display: flex;
  align-items: center;
}

.h_head div img {
  cursor: pointer;
}

.h_head span {
  color: #9da6b7;
  margin-left: 10px;
}

.h_stats {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.h_stats h2 {
  font-size: 1.2rem;
  color: #160e4d;
}

.h_stats .select_box {
  border-radius: 0 !important;
}

.select_box div {
  padding: 10px;
  font-size: 0.9rem;
}

.select_box fieldset {
  border: none;
}

.select_box:focus {
  outline: none;
}

.stats_wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 40px;
}

.stats_box {
  background: linear-gradient(32deg, #854dfd -30.19%, #4da8fd 79.2%);
  border-radius: 8px;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.stats_box:hover {
  box-shadow: rgb(99 99 99 / 55%) 0px 2px 8px 0px;
}

.st_h span {
  font-family: "Gilroy-Medium";
  margin-right: 10px;
}

.st_h span,
.stats_box h6,
.stats_box p {
  color: #ffffff;
}

.stats_box h6 {
  font-family: "Gilroy-Bold";
  margin: 10px 0;
  font-size: 1.2rem;
}

.stats_box p {
  font-family: "Gilroy-Regular";
}

.total_appointments {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.ta_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.tah_1 {
  display: flex;
  align-items: center;
}

.tah_1 h2 {
  font-size: 1.2rem;
  color: #160e4d;
}

.tah_1 span {
  color: #abb1bf;
  margin-left: 10px;
}

.tah_2 {
  display: flex;
  align-items: center;
}

/* .tah_2 div:nth-child(1) {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 10px;
  margin-right: 10px;
} */

.tah_2 input {
}

.tah_2 div:nth-child(2) {
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tah_2 div:nth-child(2) img {
  cursor: pointer;
}

.table_wrap {
  margin-top: 20px;
  width: 100%;
  overflow-y: auto;
  max-height: 93vh;
}

.table_wrap table {
  width: 100%;
  background-color: #ffffff;
  padding: 20px;
  border-collapse: separate;
  border-spacing: 0 0.8em;
}

thead {
  text-align: left;
}

thead th {
  padding: 0px 10px 0px 10px;
  color: #160e4d;
  border-bottom: none;
}

thead th:nth-child(1) {
  padding: 0px 10px 0px 18px;
}

.table_wrap table tbody tr {
  background: #f5f7f9;
  cursor: pointer;
  transition: 0.2s all ease;
}

.table_wrap table tbody tr:hover {
  transform: translateY(-4px);
  transition: 0.2s all ease;
}

.table_wrap table tbody tr td {
  /* margin: 10px 0; */
  /* padding: 10px 0 10px 10px; */
  color: #160e4d;
  font-family: "Gilroy-SemiBold";
  font-weight: 500;
}

.table_wrap table tbody tr td:nth-child(1) {
  padding: 10px 0 10px 18px;
}

.table_wrap table tbody tr td:nth-child(2) {
  display: flex;
  align-items: center;
}

.table_wrap table tbody tr td:nth-child(2) img {
  margin-right: 20px;
}

.table_wrap table tbody tr td:nth-child(3) {
  color: #0277fa;
}

@media screen and (max-width: 1230px) {
  .stats_box {
    min-height: 122px;
  }
  .table_wrap {
    overflow: auto;
  }
  .table_wrap table {
    width: max-content;
  }
}

@media screen and (max-width: 1170px) {
  .stats_box {
    width: 20%;
  }
}

@media screen and (max-width: 992px) {
  .main_wrap {
    justify-content: space-between;
  }
  .home_wrap {
    width: 100%;
    padding: 60px 0 30px 0;
  }
  .table_wrap table {
    width: 100%;
  }
}

@media screen and (max-width: 660px) {
  .table_wrap table {
    width: max-content;
  }
}

@media screen and (max-width: 575px) {
  .ta_head {
    flex-direction: column;
  }
  .tah_1 {
    margin-bottom: 10px;
  }
  .stats_box {
    width: 45%;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 460px) {
  .h_head h2,
  .h_stats h2,
  .tah_1 h2 {
    font-size: 1rem;
  }
  .stats_box {
    width: 43%;
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 360px) {
  .h_head span {
    font-size: 0.8rem;
  }
  .stats_box {
    width: 42%;
    margin-bottom: 12px;
  }
}
