.right_sidebar {
    width: 266px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    padding: 50px 10px 10px 10px;
  }
  
  .user_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
  }
  
  .user_content h2 {
    color: #160e4d;
    letter-spacing: 0.01em;
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .user_content div {
    width: 120px;
    display: flex;
    justify-content: space-between;
  }
  
  .user_content img {
    cursor: pointer;
  }
  
  .user_content p {
    color: #83879b;
    margin-top: 10px;
  }
  
  .user_stats_wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .us_box {
    width: 105px;
    height: 90px;
    background-repeat: no-repeat;
    border-radius: 8px;
    padding: 10px 10px 15px 10px;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 18px;
    cursor: pointer;
  }
  
  .us_box:hover {
    box-shadow: rgb(99 99 99 / 55%) 0px 2px 8px 0px;
  }
  
  .us_box1 {
    background: url("./SHAPE.svg"),
      linear-gradient(65.63deg, #854dfd -8.83%, #4da8fd 106.83%);
  }
  
  .us_box2 {
    background: url("./SHAPE.svg"),
      linear-gradient(314.42deg, #854dfd -58.99%, #4da8fd 122.86%);
  }
  
  .us_box3 {
    background: url("./SHAPE.svg"),
      linear-gradient(192.41deg, #854dfd -36.75%, #4da8fd 101.23%);
  }
  
  .us_box4 {
    background: url("./SHAPE.svg"),
      linear-gradient(10.22deg, #854dfd -10.79%, #4da8fd 119.9%);
  }
  
  .us_head {
    display: flex;
    align-items: center;
  }
  
  .us_head img {
    margin-right: 10px;
  }
  
  .us_head span {
    color: #ffffff;
  }
  
  .chart_wrap {
    max-width: 100%;
  }
  
  .chart_wrap::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: #160e4d;
  }
  
  .recharts-responsive-container {
    width: 303px !important;
    height: 272px !important;
  }
  
  .recharts-legend-item span {
    color: #160e4d !important;
  }
  
  .right_sidebar select {
    width: 80px;
  }
  
  .recharts-wrapper {
    left: -21px;
  }
  
  /* @media screen and (min-width: 1700px) {
    .right_sidebar {
      width: 24%;
      padding: 50px 15px 10px 15px;
    }
    .us_box {
      width: 42%;
    }
    html .recharts-responsive-container {
      width: unset !important;
      height: unset !important;
    }
  } */
  