.sidebar {
  width: 252px;
  min-height: 100vh;
  padding: 30px;
  background: #ffffff;
  border: 1px solid #f6f5f8;
}

.nav_logo {
  display: flex;
  align-items: center;
  color: #160e4d;
}

.nav_logo h2 {
  font-family: "MYRIADPRO-REGULAR";
  font-weight: 600;
}

.nav_logo img {
  margin-right: 10px;
  width: 140px;
  object-fit: contain;
}

.nav_list {
  list-style: none;
  line-height: 30px;
  margin-top: 50px;
}

.nav_list List {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.nav_list List:hover {
  opacity: 0.75;
  cursor: pointer;
}

.active_link {
  font-family: "Gilroy-SemiBold";
  color: #ff3386 !important;
}

.nav_list li div {
  color: #83879b;
  margin-left: 12px;
}

.nav_list li a {
  color: #83879b;
  margin-left: 12px;
}

.nav_list hr {
  background-color: #f6f5f8;
  color: #f6f5f8;
  width: 100%;
  opacity: 0.1;
}

a {
  color: inherit;
  text-decoration: none;
}

.menu_icon,
.menu_icon_right,
.close_icon,
.close_icon_right {
  display: none;
}

@media screen and (max-width: 992px) {
  .sideBox {
    display: none;
  }
  .menu_icon,
  .menu_icon_right,
  .close_icon,
  .close_icon_right {
    display: block;
    cursor: pointer;
  }
  .sidebar {
    position: fixed;
    transition: 0.6s all;
    left: -1000px;
    height: 90vh;
    overflow-y: auto;
    z-index: 99999;
    min-height: unset;
  }
  .right_sidebar {
    position: fixed;
    transition: 0.6s all;
    right: -1000px;
    height: 90vh;
    overflow-y: auto;
    z-index: 99999;
  }
  .sidebar::-webkit-scrollbar,
  .right_sidebar::-webkit-scrollbar {
    width: 2px;
  }
  .menu_icon {
    position: absolute;
    z-index: 999999;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu_icon_right {
    position: absolute;
    z-index: 999999;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .close_icon {
    position: absolute;
    z-index: 9999;
    top: 10px;
    left: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
  }
  .close_icon_right {
    position: absolute;
    z-index: 9999;
    top: 10px;
    right: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
  }
  .active_sidebar {
    left: 0;
    transition: 0.6s all;
  }
  .active_rightsidebar {
    right: 0;
    transition: 0.6s all;
  }
  .show_right {
    opacity: 1;
    visibility: visible;
    transition: 0.3s all;
  }
  .show {
    opacity: 1;
    visibility: visible;
    transition: 0.3s all;
  }
  .close {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all;
  }
  .close_right {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all;
  }
}
